import React from "react";

import './Footer.css'


const Footer = () => {
  return (
    <footer>
      <span><b>Created by</b> Seonghun Park<br /><b>Contact</b> seonghun120614@gmail.com</span>
      <p>&copy; 2024 <a href="mailto:seonghun120614@gmail.com">Seonghun Park</a>. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;