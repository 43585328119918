import React from "react";

import './Header.css';


const Header = () => {
  return (
    <header>
      <h1>Show Your Data</h1>
    </header>
  );
};

export default Header;